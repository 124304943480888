
@use "sass:map";
@import "~@oup/shared-front-end/src/styles/breakpoints";
@import "../../styles/constants/breakpoints";
@import "../../styles/constants/colors";
@import '../../styles/constants/variables';
@import "../../styles/mixins/hub/hub-button-focus";

/* stylelint-disable no-descending-specificity */
/* stylelint-disable selector-max-compound-selectors */
/* stylelint-disable max-nesting-depth */
.siteHeader {
  height: 60px;
  border-bottom: 1px solid $color-border-header;
  background-color: $color-white;

  // The menuButton gets hidden when not userSignedIn:
  // See notes below about moving the helpButton around.
  [hidden] {
    display: none;
  }

  @media #{$xsMedia} {
    // ...BUT only visible when the BurgerMenu is hidden when not userSignedIn:
    .menuButton:not([hidden]) ~ .helpButton {
      display: none;
    }
  }

  :global(.col) {
    display: flex;
    flex-direction: row;
  }

  nav {
    flex-grow: 1;

    > ul {
      display: flex;

      // Same height as .siteHeader:
      height: 61px;
      flex-direction: row;
      justify-content: space-between;

      > * {
        display: inline-block;
        height: 100%;

        > a {
          display: inline-block;

          &:hover,
          &:focus {
            background-color: $color-black-rgba;
            mix-blend-mode: normal;
          }
        }
      }
    }
  }

  .myAccountText {
    display: none;

    @media #{$smMedia} {
      display: block;
      margin-right: auto;
      margin-left: 20px;
    }

    > * {
      padding-top: 20px;
      color: map.get($hubColors, blue-color-dark);
      font-family: Georgia, serif;
      font-size: 18px;
    }
  }

  .menuButton,
  .searchButton,
  .accountButton {
    > a,
    > button {
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }

  .impersonationAccountButton {
    align-items: center;
    justify-content: center;
    border-radius: 0;
  }

  .accountButton button > div {
    // Keep the thumbnail aligned on iOS 10
    margin: 0 auto;
  }

  .oxfordLogo {
    margin-top: 15px;

    svg {
      height: 30px;
      flex-shrink: 1;
      fill: color(primary-dark);
    }
  }

  .orbMobileLogo {
    display: none;
  }

  .orbLogo {
    margin-top: -10px;

    svg {
      height: 80px;
      flex-shrink: 1;
      fill: color(primary-dark);
    }
  }

  .courseSelector {
    margin-top: 10px;
  }

  .menuButton {
    height: 32px;
    margin-left: -12px;

    @media #{$smMedia} {
      display: none;
    }

    button svg {
      border: 1px solid $color-botton;
      border-radius: 4px;

      &:hover,
      &:focus {
        background-color: $color-botton;
      }
    }

    svg {
      width: 41px;
      height: 30px;
      padding: 4px 10px 5px;
      fill: $color-grey-border;
    }
  }

  // Align rightmost button on RHS: (Most noticeable on XS breakpoint)
  .accountButton {
    margin-right: -12px;
  }

  .loginButton {
    padding: 18px 0;

    a {
      display: inline-flex;
    }

    button {
      margin-left: 15px;
    }

    .registerButton {
      display: none;
    }
  }

  @media #{$smMedia} {
    li {
      position: relative; // Fix the popups to the li
    }

    .loginButton {
      display: flex;

      .registerButton {
        display: inline-flex;
      }

      a {
        margin-left: 15px;
      }
    }
  }

  @media screen and (width <= 1006px) {
    .orbLogo {
      svg {
        display: none;

        &.orbMobileLogo {
          display: block;
        }
      }
    }
  }
}

body[data-theme="dark-theme"] {
  .siteHeader {
    border-bottom: none;

    @media #{$xsMedia} {
      .helpButton {
        order: 0;
      }
    }

    .oxfordLogo {
      margin-top: 10px;

      svg {
        width: auto;
        height: 40px;
        fill: $color-white;
      }
    }

    .searchButton {
      margin-left: auto;
    }

    .notificationsButton {
      a {
        display: flex;
        width: auto;
        align-items: center;

        &:hover {
          background-color: transparent;
          box-shadow: none;
        }

        > svg {
          min-width: 40px;
        }

        span {
          display: flex;
          min-width: 30px;
          align-items: center;
          justify-content: center;
          padding: 0 8px;
          border-radius: 10px;
          background: $color-very-light-grey;
        }
      }
    }

    .courseHomeLink {
      color: var(--service-brand-darkest);

      svg {
        width: 40px;
        border-radius: 100%;
        background-color: $color-white;
      }
    }

    .searchButton,
    .helpButton,
    .notificationsButton {
      a,
      button {
        display: block;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        margin: 10px 0 10px 10px;
        box-shadow: inset 0 0 0 1.5px transparent;
        color: $color-white;

        &:hover {
          background-color: $contrast-light-box-shadow;
          box-shadow: inset 0 0 0 1.5px $color-white;
        }
      }

      svg {
        height: 40px;
        padding: 0;
      }
    }

    .accountButton {
      margin-left: 10px;
    }

    .helpButton,
    .accountButton {
      > .open,
      > :active {
        background-color: transparent;
      }
    }

    .accountButton button {
      display: block;
      padding: 0;

      > div {
        width: 40px;
        height: 40px;
        background-color: transparent;
        box-shadow: inset 0 0 0 1.5px $color-white;
        line-height: 40px;

        &:hover {
          background-color: $contrast-light-box-shadow;
        }
      }
    }
  }
}

body[data-theme="light-theme"] {
  .siteHeader {
    border-bottom: 1px solid map.get($background-and-border-colors, border);

    @media #{$xsMedia} {
      .helpButton {
        order: 0;
      }
    }

    .courseHomeLink svg {
      height: 40px;
      border: 1px solid map.get($background-and-border-colors, background-light-blue);
      border-radius: 50%;
      margin-top: -5px;
    }

    .searchButton {
      margin-left: auto;
    }
  }
}

body[data-theme="hub-theme"],
body[data-theme="rvs-theme"],
body[data-theme="ocp-theme"] {
  .siteHeader {
    height: 75px;

    div:first-child a:first-of-type {
      display: inline-flex;
      margin-right: 8px;
    }

    .oupLogoContainer:focus {
      @include standart-focus-button;

      border-radius: 3px;
    }

    nav > ul {
      height: 75px;

      li {
        display: flex;
        margin: auto 0;

        &.oxfordLogo {
          margin-right: auto;

          > * {
            margin: auto;

            svg {
              width: 181px;
              height: 76px;
            }
          }

          a {
            height: 100%;
            outline: none;
          }
        }

        &.menuButton + .oxfordLogo {
          > * {
            padding-right: 32px;
            padding-left: 26px;
          }
        }

        &.loginButton {
          a {
            border-color: color(border);
            border-radius: 20px;

            > span {
              &::before {
                top: 9px;
              }
            }

            &:hover {
              border-color: color(border);
            }
          }

          button {
            border-color: map.get($background-and-border-colors, border-grey);
            border-radius: 20px;
            box-shadow: none;
            color: $white-grey;

            svg {
              width: 1rem;
              height: 1rem;
            }

            svg + span::before {
              background-color: map.get($hubColors, hub_link);
            }

            &:hover {
              border-color: $color-white;
              background-color: transparent;
              color: $color-white;
            }

            &:focus {
              border-color: map.get($primary-colors, primary);
              box-shadow:
                0 0 0 1px $color-white,
                inset 0 0 0 1px map.get($primary-colors, primary),
                inset 0 0 0 2px $color-white;
            }
          }
        }

        &.languageSelector {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 8px 0 auto;
        }

        &.helpButton {
          margin-left: auto;

          a {
            padding: 10px;
            border: 1px solid transparent;
            border-radius: 5px;
            margin: auto;
            color: map.get($hubColors, hub_link);

            svg {
              width: 20px;
              height: 20px;
              fill: map.get($hubColors, hub_link);
            }

            &:hover {
              border-color: map.get($background-and-border-colors, border);
            }

            &:focus {
              border-color: map.get($primary-colors, primary);
              box-shadow:
                0 0 0 1px $color-white,
                inset 0 0 0 1px map.get($primary-colors, primary),
                inset 0 0 0 2px $color-white;
            }

            &:hover,
            &:focus {
              color: map.get($hubColors, hub_link_hover);

              svg {
                fill: map.get($hubColors, hub_link_hover);
              }
            }
          }

          .helpSupportText {
            display: block;
            padding-left: 10px;
            line-height: 1.25rem;
          }
        }

        &.languageSelector + .helpButton {
          margin-left: 0;
        }

        &.accountButton {
          button {
            height: 51px;
            margin: auto;
          }

          .accountButtonHub {
            width: 3.125rem;
            height: 3.125rem;
            padding: 0;
            border: solid 1px transparent;
            border-radius: 4px;
            outline: none;

            &:hover,
            &.open {
              border-color: color(border);

              > div {
                background-color: map.get($hubColors, hub_link_hover);
              }
            }

            &:focus,
            &.open {
              background-color: map.get($secondary-colors, hub_light_grey);
            }

            &:focus {
              border-color: map.get($primary-colors, primary);
              box-shadow:
                0 0 0 1px $color-white,
                inset 0 0 0 1px map.get($primary-colors, primary);

              > div {
                background-color: map.get($hubColors, hub_link_hover);
              }
            }
          }
        }

        &.menuButton {
          height: auto;
          margin-left: -24px;

          button {
            margin: auto;
            outline: none;

            &:hover {
              svg {
                border-color: color(border);
                border-radius: 4px;
                fill: color(text);
              }
            }

            &:active {
              background-color: initial;
            }

            &:focus svg {
              border-color: map.get($primary-colors, primary);
              box-shadow:
                0 0 0 1px $color-white,
                inset 0 0 0 1px map.get($primary-colors, primary),
                inset  0 0 0 2px $color-white;
            }

            svg {
              width: 50px;
              height: 50px;
              padding: 12px;
              border: 1px solid transparent;
              fill: $color-grey;
            }
          }

          .hubMenuSelected {
            svg {
              border: 1px solid color(border);
              background-color: map.get($secondary-colors, hub_light_grey);
              fill: color(text);
            }
          }
        }
      }
    }

    &.darkBackground {
      border-bottom: 1px solid map.get($background-and-border-colors, border-grey);
      background-color: map.get($background-and-border-colors, background-dark-blue);
      font-family: $open-sans;
      font-weight: 400;

      nav > ul {
        li {
          &.helpButton {
            a {
              color: $white-grey;

              svg {
                fill: $white-grey;
              }

              &:hover {
                border-color: color(border);
                color: $color-white;

                svg {
                  fill: $color-white;
                }
              }
            }
          }
        }
      }
    }

    .menuButton {
      display: block;
    }

    @media #{$smMedia} {
      .loginButton {
        display: flex;

        a {
          margin: 0;

          &:not(:last-child) {
            margin: 0 12px 0 0;
          }
        }
      }
    }

    @media #{$xsMedia} {
      .helpButton {
        display: flex;
        order: inherit;
      }

      .menuButton:not([hidden]) ~ .helpButton {
        display: flex;
      }
    }

    @media #{$phoneMedia} {
      .oxfordLogo {
        margin-right: 8px;
      }

      .loginButton {
        a {
          svg {
            display: none;
          }

          span {
            margin-left: 7px;

            &::before {
              display: none;
            }
          }
        }

        button {
          margin-left: 0;
        }
      }

      nav > ul {
        li {
          &.menuButton {
            margin-left: -16px;

            + .oxfordLogo {
              > * {
                padding-right: 16px;
                padding-left: 16px;
              }
            }
          }

          &.oxfordLogo {
            svg {
              width: 215px;
              margin: 0 -44px;
            }
          }

          &.helpButton {
            a {
              svg {
                width: 30px;
                height: 30px;
              }
            }

            .helpSupportText {
              display: none;
            }
          }
        }
      }
    }
  }
}

body[data-theme="rvs-theme"] {
  .siteHeader {
    &.darkBackground {
      nav > ul {
        li {
          &.helpButton {
            a {
              &:hover {
                border-color: map.get($background-and-border-colors, border-grey);
              }
            }
          }
        }
      }
    }
  }
}

.offlineOxfordLogo {
  margin-right: 0 !important;

  @media screen and (max-width: $screen-width-600) {
    display: none !important;
  }
}

.offlineList {
  justify-content: flex-start !important;
}

.offlineContainer {
  display: flex !important;
  height: auto;
  align-items: center;
  justify-content: center;
}

.offlineDelimiter {
  height: 60%;
  border: none;
  border-left: 1px solid #334d6c !important;
  margin: 0 20px;

  @media screen and (max-width: $screen-width-600) {
    display: none;
  }
}

.offlinePageText {
  height: auto;
  margin-left: 9px;
  color: white;
  font-family: $font-family-display-font;
}
