@use "sass:map";
@import "../../../styles/constants/colors";
@import "../../../styles/constants/breakpoints";
@import "../../../styles/mixins/hub/hub-button-focus";
@import "~@oup/shared-front-end/src/styles/themes/base/typography";

.offlineModal {
  position: fixed;
  z-index: 102;
  display: flex;
  width: 100%;
  min-height: 100vh;
  justify-content: center;
  padding: 0;
  margin: 0;
  background-color: transparent;
  font-family: $font-family-text-font;
  inset: 0;
  opacity: 1;
  pointer-events: none;
  transition: 0.3s background-color, z-index ease-in-out;

  &.open {
    z-index: 99999999;
    background-color: $contrast-light-hover;
    pointer-events: unset;
    visibility: initial;
  }

  .modalContainer {
    position: absolute;
    top: -30%;
    left: 50%;
    width: 480px;
    max-width: 480px;
    height: 337px;
    border-radius: 24px;
    background-color: $color-white;
    transform: translate(-50%, -50%);
    transition: 0.3s top linear;

    @media #{$xsMedia} {
      min-width: 20rem;
      max-width: 250px;
    }

    &.animationFadeIn {
      top: 35%;
    }

    .modalHeader {
      height: 91px;
      padding: 5px;
      text-align: right;

      button {
        width: 5.75rem;
        min-height: 1.875rem;

        &:hover,
        &:focus {
          background-color: map.get($secondary-colors, hub_light_grey);
          color: map.get($hubColors, hub_dark_blue);
          filter: brightness(100%);

          svg {
            fill: map.get($hubColors, hub_dark_blue);
          }
        }

        &:focus {
          @include standart-focus-button;
        }
      }

      .title {
        margin: 0 0 10px 10px;
        font-size: $font-size-large;
        font-weight: $font-weight-600;
        line-height: $line-height-default-150;
        text-align: left;

        @media #{$xsMedia} {
          font-size: 1.5rem;
        }
      }
    }

    .modalOfflineContent {
      display: flex;
      height: 174px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    .modalFooter {
      display: flex;
      height: 72px;
      align-items: center;
      justify-content: space-between;
      padding: 1.5rem;

      button:focus {
        @include standart-focus-button;
      }

      button:first-of-type {
        padding: 0;
        color: map.get($hubColors, hub_link);

        &:hover {
          color: map.get($hubColors, hub_dark_blue);
        }
      }

      button:last-of-type {
        border-bottom: 0;
      }
    }
  }

  .cancelButton, .confirmButton {
    width: 93px;
    height: 48px;
    padding: 11px 15px;
    border-radius: 8px !important;
  }

  .offlineModalCloseButton {
    font-size: 14px;
  }

  .offlineModalCloseButton svg {
    width: 17px;
    height: 17px;
  }
}
