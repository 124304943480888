.container {
  width: 100%;
  height: 100%;

  iframe {
    width: 100%;
    height: 100%;
  }
}

.hide {
  display: none;
}

.show {
  display: block;
}
