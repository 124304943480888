@use "sass:map";
@import "~@oup/shared-front-end/src/styles/themes/base/typography";
@import "../../../styles/constants/colors";

.container {
  width: 100%;
  max-width: 1024px;
  padding: 0 24px;
  margin: 30px auto;
}

.container h1.title {
  width: 100%;
  padding-bottom: 10px;
  margin-top: 2rem;
  margin-bottom: 0;
  color: map.get($cptColors, dark_blue);
  font-size: $font-size-2xlarge;
  font-weight: 700;
  line-height: 110%;
}

.container .backButton {
  display: block;
  font-size: 0.9rem;
}

.container .backIcon {
  position: relative;
  top: 0.10rem;
  left: -0.25rem;
  height: 1rem;
  margin-right: 0.25rem;
  float: left;
}
